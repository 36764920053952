$contentintelligence-purple: #51378e;
$contentintelligence-orange: #df8d46;
$contentintelligence-orange-light: #f1c19c;
$contentintelligence-orange-hover: #f1dfd0;
$contentintelligence-grey: #b0b0b0;

:export {
    purple: $contentintelligence-purple;
    orangeLight: $contentintelligence-orange-light;
    orangeHover: $contentintelligence-orange-hover;
    orange: $contentintelligence-orange;
    grey: $contentintelligence-grey;
}


            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    svg {
        path {
            fill: var(--iconColor);
            
        }
    }
}


.mainSection{
position: relative;
    &::before{
    
    content: "";
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    height: 100%;
    position: absolute;
    z-index: -1;
   
  
    background: var(--bg);
    
  background-repeat: no-repeat;
  background-size: cover;
    background-position: bottom;
}
}


.link {
    position: relative;
    &:hover {
        &::before {
            width: 100%;
        }
    }
    &::before {
        content: "";
        transition: width 0.3s ease-in-out;
        position: absolute;
        bottom: -10px;
        width: 0%;
        height: 3px;
        background-color: $contentintelligence-orange;
        z-index: -1;
        &:hover {
            width: 100%;
        }
    }
}

